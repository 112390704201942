.App {
  text-align: center;
  margin-top: 30px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button:hover {
  background-color: #f0f0f0;
}

button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.form-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  gap: 15px; /* Space between items */
  max-width: 300px; /* Set a max width for the form */
  margin: 0 auto; /* Center the form horizontally */
}

.form-container input,
.form-container button {
  padding: 5px;
  font-size: 12px;
}

.form-container label {
  margin-bottom: 5px;
}

.form-container button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form-container button:hover {
  background-color: #0056b3;
}
